@use "mixins";

.media-wrapper {
  display: inline-block;
  font-size: 0;
  vertical-align: top;

  &.size-width {
    max-width: var(--max-width, auto);
    min-width: var(--min-width, 48px);
    width: var(--width, initial);
  }

  .media-wrapper-inner {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  &.size-height {
    .media-wrapper-inner {
      max-height: var(--max-height, auto);
      min-height: var(--min-height, 48px);
      padding-top: var(--height, initial);


      > img,
      iframe,
      .media {
        border-color: transparent;
        border-radius: 3px;
        height: 100%;
        inset: 0;
        position: absolute;
        width: 100%;
      }
    }
  }

  &.loading {
    img,
    iframe {
      @include mixins.loading-animation();
    }
  }
}

.picture {
  background: var(--color-img-background);
  border-radius: 6px;
  box-shadow: 0 6px 28px var(--color-img-shadow);
  display: inline-block;
  margin: 20px;
  max-width: 70%;
  padding: 6px;
  width: calc(45 * var(--vh, 1vh));

  &:last-of-type {
    margin-bottom: 0;
  }
}

