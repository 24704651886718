@use "/src/style/mixins";

@mixin flash-button {
  .button-flash {
    display: inline-block;
    position: relative;
    font-family: "Inter-Web";
    font-weight: 600;
    width: auto;
    margin: 20px auto;
    letter-spacing: -0.03em;
    vertical-align: middle;
    font-size: 2em;
    padding: 25px 40px;
    border-width: 0;
    border-style: solid;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    border-radius: 10px;
    background: #1813c6
      linear-gradient(0deg, #1813c6 0%, #7373ff 70%, #4d55f4 100%);
    background-size: 100% 300%;
    background-position-y: 100%;
    color: #fff;
    transform-origin: 50%;
    transition-delay: 0s, 0.2s, 0s;
    transition: box-shadow 0.3s, transform 0.3s, color 0.3s,
    background-position-y 0.3s;
    @include mixins.respond-to("large") {
      font-size: 1.5em;
      padding: 20px 30px;
    }
    &:hover {
      background-position-y: 0%;
      color: white;
      box-shadow: 0 0 5px rgba(#4d55f4, 20%), 0 0 10px rgba(white, 20%),
        0 0 15px rgba(#4d55f4, 20%), 0 5px 10px rgba(0, 0, 0, 0.25);
      transform: scale(1.05);
      transition: box-shadow 0.3s, transform 0.3s, color 0.3s,
        background-position-y 0.3s;
    }
  }
}
