@use "fonts";
@use "reset";
@use "colors";
@use "keyframes";
@use "mixins";
@use "responsive";
@use "layout";
@use "text";
@use "link";
@use "button";
@use "a11y";
@use "media";
@use "animations";
@use "keyboard";
@use "pages";
@use "form";


p {
  > svg {
    display: inline-block;
    vertical-align: middle;
  }
}
