@use "/src/style/mixins";

a {
  color: var(--color-primary);
  font-size: 16px;
  text-decoration: none;
}

.underline {
  display: inline-block;
  position: relative;
  transition: color 0.2s ease-in-out;

  &::after {
    background-color: currentColor;
    bottom: 0;
    content: "";
    height: 0.0625em;
    left: 0;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transform-origin: 0 center;
    transition: transform 0.4s cubic-bezier(1, 0, 0, 1), color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    @include mixins.break-below("small") {
      display: none;
    }
  }

  &:hover,
  &:focus {
    outline: none;

    &::after {
      transform: scaleX(1);
    }
  }
}
