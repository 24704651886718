@use "/src/style/mixins";
@use "/src/style/palette";

@mixin transition {
  .hero-transition {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: fixed;

      rect.hero-svg-rect {
        fill: #fff;
        @media (prefers-color-scheme: dark) {
          fill: black;
        }
      }
    }

    canvas {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background: transparent;
    }
  }
}