$black: black; // stylelint-disable-line
$white: white; // stylelint-disable-line

/**
 * Light theme palette
 */
$light-niobium: #393e47;
$light-corduroy: #797b80;
$light-stone-gray: #a6a8ab;
$light-alpha-gray: #c6c8cc;
$light-mercury: #f0f0f2;
$light-nero: #fafafa;

$light-beam: #5e2bff;
$light-beam-active: #4a1be0;
$light-bluetiful: #1179cf;
$light-charmed-green: #0e8763;
$light-sanskrit: #f90;
$light-shiraz: #b00230;
$light-munsell: #f2003c;
$light-fuschia: #f056e0;
$light-blue-light: #8e8ef9;

/**
 * Dark theme palette
 */
$dark-niobium: #ededed;
$dark-corduroy: #a4a4a4;
$dark-stone-gray: #737373;
$dark-alpha-gray: #4d4d4d;
$dark-mercury: #2a2a2e;
$dark-nero: #232326;

$dark-beam: #7373ff;
$dark-beam-active: #5c5ccc;
$dark-bluetiful: #3a96cf;
$dark-charmed-green: #12b282;
$dark-sanskrit: #ffb240;
$dark-shiraz: #cf3a61;
$dark-munsell: #ff4778;
$dark-blue-light: #8e8ef9;
