@use "/src/style/mixins";
@use "/src/style/palette";
@use "hero/transition";

@mixin hero {
  @keyframes arrow-mode-down {
    0%   { transform:translate(0,-20px); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { transform:translate(0,20px); opacity: 0; }
  }

  .hero {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    overflow: hidden;
    // padding-top: 70px;
    z-index: 150;

    @include transition.transition;

    .bg-gradient {
      @include mixins.gradient-bg();
      transition: opacity 1s;
      @include mixins.break-below("small") {
        height: calc(100% + 100px);
        position: absolute;
      }
      .glow & {
        opacity: 0 !important;
      }
    }

    .container {
      z-index: 100;
    }

    p {
      padding: 0.6em 0;
      @include mixins.break-below("small") {
        padding: 0;
      }
    }

    .beam-icon {
      margin-bottom: 20px;
      svg, img {
        display: block;
        width: 70px;
        height: auto;
        margin: 0 auto;
      }
    }

    .scroll {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 30px;

      svg {
        display: block;
        width: 25px;
        height: auto;
        position: relative;
        margin: 0 auto;
        opacity: 0;
        transform: translate(0,-10px);
        animation: arrow-move-down 2s ease-in-out infinite;

        path {
          fill: rgba(palette.$black, 0.15);
          @media (prefers-color-scheme: dark) {
            fill: rgba(palette.$white, 0.25);
          }
        }
      }
    }

    .tagline {
      @include mixins.break-below("small") {
        font-size: 0.8em;
      }
      @include mixins.break-below("tiny") {
        font-size: 3.25vw;
      }
      &.top {
        font-weight: 600;
        color: var(--color-light);
      }
      &.bottom {
        font-weight: 300;
        line-height: 140%;
      }
    }

    .glowtext {
      @media (prefers-color-scheme: dark) {
        opacity: 1;
      }
      .glow & {
        color: gold;
        opacity: 1;
        @media (prefers-color-scheme: dark) {
          text-shadow: 0 0 20px rgb(gold, 50%);
        }
      }
    }

    h1 {
      margin-top: 40px;
      margin-bottom: 0;
      font-size: 4em;
      letter-spacing: -0.04em;
      font-weight: 400;
      white-space: nowrap;

      strong {
        font-weight: 600;
        color: var(--color-primary);
      }
      @include mixins.break-below("large") {
        font-size: 3.5em;
      }
      @include mixins.break-below("medium") {
        font-size: 3em;
      }
      @include mixins.break-below("small") {
        font-size: 2.5em;
      }
      @include mixins.break-below(568px) {
        font-size: 2em;
      }
      @include mixins.break-below("tiny") {
        font-size: 8vw;
      }
    }

    button {
      @include mixins.break-below("tiny") {
        font-size: 5vw;
        padding: 3vw 5vw;
        border-radius: 6px;
      }
    }

    .os-version {
      opacity: 0.5;
      @include mixins.break-below("tiny") {
        font-size: 3.5vw;
      }
    }
  }
}
