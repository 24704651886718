@use "/src/style/mixins";
@use "/src/style/palette";
@use "home/background";

@mixin about {
  .about {
    @include background.background;

    > header {
      left: var(--site-padding);
      position: fixed;
      right: var(--site-padding);
      top: var(--site-padding);
      width: initial;
    }

    .about-container {
      margin-top: 150px;
      @include mixins.break-below("medium") {
        margin-top: 125px;
      }
      @include mixins.break-below("small") {
        margin-top: 100px;
      }
      @include mixins.break-below("mini") {
        margin-top: 70px;
      }

      .about-items {
        padding-top: 50px;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          top: 0;
          left: -5vw;
          width: calc(100% + 10vw);
          height: 100%;
          border-radius: 20px;
          opacity: 0.2;
          background: linear-gradient(
            to top,
            rgba(#ff73ef, 0) 0%,
            rgba(#7cbaf7, 1) 100%
          );
          @include mixins.break-below("mini") {
            width: calc(100% + 20px);
            left: -10px;
          }
        }
        &:last-child {
          margin-bottom: 30px;
        }

        h3 {
          text-align: center;
          margin-bottom: 60px;
          @include mixins.break-below("mini") {
            margin-bottom: 40px;
          }
        }
      }

      .about-item {
        position: relative;
        margin: 0 auto 50px auto;
        @include mixins.break-below("mini") {
          margin: 0 auto 50px auto;
          padding: 0 20px;
        }
        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      .team,
      .investors {
        ul {
          display: flex;
          width: calc(100% + 20px);
          flex-wrap: wrap;
          margin: 0;
          margin-left: -20px;
          padding: 0;
          list-style-type: none;
          @include mixins.break-below("mini") {
            width: 100%;
            margin-left: 0;
          }

          li {
            display: block;
            margin: 0;
            padding: 0;
            width: calc(33.33% - 20px);
            margin-left: 20px;
            margin-bottom: 30px;
            text-align: center;
            list-style-type: none;
            @include mixins.break-below("mini") {
              display: flex;
              width: 100%;
              margin-left: 0;
              text-align: left;
            }

            .image {
              margin: 0 auto 5px auto;
              @include mixins.break-below("mini") {
                margin: 0;
              }

              .img {
                display: block;
                width: 80px;
                height: 80px;
                position: relative;
                margin: 0 auto;
                border-radius: 12px;
                background-color: palette.$light-mercury;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-image: url(/assets/about/team-generic_light.png);
                @include mixins.break-below("mini") {
                  width: 70px;
                  height: 70px;
                  margin: 0;
                }
                @media (prefers-color-scheme: dark) {
                  background-color: palette.$dark-mercury;
                  background-image: url(/assets/about/team-generic_dark.png);
                }
                &:after {
                  content: "";
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 12px;
                  box-sizing: border-box;
                  border: 1px solid rgba(palette.$black, 0.07);
                  @media (prefers-color-scheme: dark) {
                    border: 1px solid rgba(palette.$black, 0.7);
                  }
                }
              }
            }

            .details {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              justify-content: center;
              @include mixins.break-below("mini") {
                margin-left: 15px;
              }

              .name {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 0;
              }

              .logo {
                display: flex;
                min-height: 21px;
                flex-grow: 0;
                flex-shrink: 1;
                align-items: center;

                img {
                  display: block;
                  width: 100%;
                  max-width: 140px;
                  height: auto;
                  margin: 0 auto;
                  @include mixins.break-below("mini") {
                    margin: 0;
                  }
                }
              }

              .title,
              .company {
                color: palette.$light-corduroy;
                font-size: 14px;
                font-weight: 500;
                @media (prefers-color-scheme: dark) {
                  color: palette.$dark-corduroy;
                }
              }
            }
          }
        }
      }

      .investors {
        ul {
          margin-bottom: 2em;
        }
      }

      h1 {
        font-size: 60px;
        font-weight: 500;
        letter-spacing: -0.02em;
        @include mixins.break-below("tiny") {
          font-size: 50px;
        }

        br {
          display: none !important;
          @include mixins.break-below("small") {
            display: block !important;
          }
        }
      }

      h2 {
        font-size: 60px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.02em;
        @include mixins.break-below("large") {
          font-size: 50px;
        }
        @include mixins.break-below("small") {
          font-size: 36px;
          line-height: 110%;
        }

        br {
          @include mixins.break-below("small") {
            display: none;
          }
        }

        span.break {
          display: block;
          @include mixins.break-below("small") {
            display: inline;
          }
        }
      }

      h3 {
        font-size: 44px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.01em;
        @include mixins.break-below("mini") {
          font-size: 28px;
        }
      }

      .blurb {
        display: block;
        margin: 0 0 40px 0;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.01em;
        text-transform: lowercase;
        @include mixins.break-below("small") {
          margin: 0 0 20px 0;
        }
      }

      h2 {
        display: block;
        margin: 0 0 40px 0;
        @include mixins.break-below("mini") {
          margin: 0 0 20px 0;
        }
      }

      h3 {
        display: block;
        margin: 0 0 20px 0;
        @include mixins.break-below("mini") {
          margin: 0 0 20px 0;
        }
      }
    }
  }
}

.svg-beam-logo-light {
  opacity: 0.5;
  transform: scaleY(1);
  animation:
    shake-opacity 0.5s ease-in-out infinite,
    shake-scaleY 3s ease-in-out infinite
  ;
}
