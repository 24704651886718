@use "style/style";

body.loading {
  opacity: 0;

  * {
    animation: none !important;
    transition: none !important;
  }

  .win {
    opacity: 0;
  }
}